<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :mini-variant-width="drawerWidth"
      :clipped="clipped"
      fixed
      app
      v-if="user.logged"
    >
      <v-list>
        <div
          class="top-nav"
          :style="{ padding: miniVariant ? '9px 10px' : '10px 6px' }"
        >
          <v-btn
            icon
            @click.stop="miniVariant = !miniVariant"
            class="burger-menu"
          >
            <img src="./assets/img/burger_menu.svg" alt="" />
          </v-btn>
        </div>

        <template v-for="item in items">
          <template>
            <v-list-item
              :key="item.title"
              :to="item.to"
              router
              exact
              @click="updatePageTitle(item.title, item.to)"
            >
              <v-list-item-action
                :style="{ margin: miniVariant ? 'auto' : '10px 15px' }"
              >
                <v-img
                  :src="item.icon"
                  :alt="item.title"
                  class="icon-image-navside"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!-- <v-divider
            :key="'divider-' + i"
            :class="['divider-custom', { 'divider-margin': !miniVariant }]"
          /> -->
        </template>
        <div v-if="miniVariant" class="horizontal-line"></div>
        <div v-if="!miniVariant" class="horizontal-line-not-full"></div>
        <div class="root-element2" v-if="user.userInf.isAdmin === true">
          <template v-for="(item, k) in items2">
            <template>
              <v-list-item
                :key="k"
                :to="item.to"
                router
                exact
                @click="updatePageTitle(item.title, item.to)"
              >
                <v-list-item-action
                  :style="{ margin: miniVariant ? 'auto' : '10px 15px' }"
                >
                  <v-img
                    :src="item.icon"
                    :alt="item.title"
                    class="icon-image-navside"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- <v-divider
            :key="'divider-' + i"
            :class="['divider-custom', { 'divider-margin': !miniVariant }]"
          /> -->
          </template>
        </div>
        <div class="root-element3" v-else>
          <template v-for="(item, k) in items3">
            <template>
              <v-list-item
                :key="k"
                :to="item.to"
                router
                exact
                @click="updatePageTitle(item.title, item.to)"
              >
                <v-list-item-action
                  :style="{ margin: miniVariant ? 'auto' : '10px 15px' }"
                >
                  <v-img
                    :src="item.icon"
                    :alt="item.title"
                    class="icon-image-navside"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- <v-divider
            :key="'divider-' + i"
            :class="['divider-custom', { 'divider-margin': !miniVariant }]"
          /> -->
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="clipped" fixed app v-if="user.logged">
      <div class="right-panel-nav">
        <!-- <Breadcrumbs :routes="items" /> -->
        <!-- <v-toolbar-title>{{ pageTitle }}</v-toolbar-title> -->

        <img
          src="./assets/img/rd_prooff_nav_logo.svg"
          class="logo-icon"
          alt="logo-img"
        />
        <div class="vertical-line"></div>
        <p class="admin-panel">Админ панель</p>
      </div>
      <div class="right-panel-nav">
        <div class="vertical-line"></div>
        <p class="nav-phone" v-if="user.logged">
          {{ user.userInf.fullName || user.userInf.name }}
        </p>
        <p class="nav-phone" v-else>Пользователь не авторизован</p>
        <div
          class="btn-nav-profile"
          @click="toggleProfileMenu"
          ref="profileButton"
        >
          <img src="./assets/img/avatar-img.png" alt="Custom Image" />
          <div class="profile-menu" v-if="showProfileMenu" ref="profileMenu">
            <router-link to="/Profile" class="profile-menu-item-1"
              >Профиль</router-link
            >
            <div class="divider-modal"></div>
            <div class="profile-menu-item-2" @click="handleReturnAndSignOut">
              Выход
            </div>
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <div v-if="pageLoader" class="page-loader">
        <div class="d-flex flex-column" style="margin: 0 auto">
          <img src="./assets/img/rd_prooff_login_logo.svg" alt="Loading..." />
          <div class="loader">
            <div class="progress"></div>
          </div>
        </div>
      </div>
      <router-view :key="$route.fullPath" />
    </v-main>
    <!-- <v-navigation-drawer v-model="rightDrawer" :right="right" temporary fixed>
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light>mdi-repeat</v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <!-- <v-footer :absolute="!fixed" app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer> -->
  </v-app>
</template>

<script>
// import Breadcrumbs from "@/components/Breadcrumbs.vue"; // Замените путь на корректный
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
/* eslint-disable */
import { getAuth, signOut } from "firebase/auth";
/* eslint-disable */
import router from "@/router";
export default {
  name: "App",
  data() {
    return {
      pageLoader: localStorage.getItem("pageLoaderState") === "true",
      clipped: true,
      drawer: true,
      fixed: false,
      pageTitle: "",
      showProfileMenu: false,
      miniVariantApplied: false,
      pageLoader: false,
      items: [
        {
          icon: require("@/assets/img/bannerNavIcon.svg"),
          to: "/BannerPage",
          title: "Баннеры",
          breadcrumbTitle: "Баннеры",
        },
        {
          icon: require("@/assets/img/categoryNavIcon.svg"),
          to: "/category-list",
          title: "Категории",
          breadcrumbTitle: "Категории",
        },
        {
          icon: require("@/assets/img/productNavIcon.svg"),
          to: "/ProductList1",
          title: "Продукты",
          breadcrumbTitle: "Продукты",
        },
        {
          icon: require("@/assets/img/catalogNavIcon.svg"),
          to: "/Catalog",
          title: "Размещение продуктов в каталоге",
          breadcrumbTitle: "Размещение продуктов в каталоге",
        },

        // {
        //   icon: require("@/assets/img/profileIcon.svg"),
        //   title: "Профиль",
        //   to: "/Profile",
        //   meta: {
        //     customLabel: "Профиль", // Добавьте это свойство customLabel
        //   },
        //   breadcrumbTitle: "Профиль",
        // },
      ],
      items2: [
        {
          icon: require("@/assets/img/postNavIcon.svg"),
          to: "/post-list",
          title: "Статьи",
          breadcrumbTitle: "Статьи",
        },
        {
          icon: require("@/assets/img/staffNavIcon.svg"),
          to: "/Staff",
          title: "Персонал",
          breadcrumbTitle: "Персонал",
        },
      ],
      items3: [
        {
          icon: require("@/assets/img/postNavIcon.svg"),
          to: "/post-list",
          title: "Статьи",
          breadcrumbTitle: "Статьи",
        },
      ],

      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "",
      drawerWidth: 57,
    };
  },
  watch: {
    miniVariant(newVal) {
      if (!newVal) {
        this.miniVariantApplied = true;
      }
    },
  },
  methods: {
    ...mapActions(["emptyUser"]),
    async handleReturnAndSignOut() {
      const auth = getAuth();
      const user = auth.currentUser;
      this.pageLoader = true;

      try {
        if (user) {
          await signOut(auth);
        }
      } catch (error) {
        console.error("Ошибка при разлогине пользователя:", error);
      }

      this.$store.dispatch("emptyUser");

      try {
        this.$router.push({ name: "Login" });
        setTimeout(() => {
          this.pageLoader = false;
        }, 1500);
      } catch (error) {
        console.error("Ошибка при перенаправлении пользователя:", error);
      }
    },
    updatePageTitle(title) {
      this.pageTitle = title;

      // Создаем новый объект для текущей крошки и добавляем его в хлебные крошки
      // const currentCrumb = { label: title, route: route };
      // this.breadcrumbs.push(currentCrumb);
    },

    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    handleClickOutside(event) {
      const profileButton = this.$refs.profileButton;
      const profileMenu = this.$refs.profileMenu;

      if (
        profileButton &&
        profileMenu &&
        !profileButton.contains(event.target) &&
        !profileMenu.contains(event.target)
      ) {
        this.showProfileMenu = false;
      }
    },

    btnSwitch() {
      this.pageLoader = true;
      this.$store.commit("setCurrentRest", {});
      // Записываем состояние pageLoader в Local Storage перед перезагрузкой
      localStorage.setItem("pageLoaderState", "true");

      // Загрузка нового маршрута
      router.push("/RestaurantChoose").then(() => {
        // Устанавливаем задержку перед перезагрузкой страницы
        setTimeout(() => {
          // Удаляем сохраненное состояние pageLoader из Local Storage
          localStorage.removeItem("pageLoaderState");

          // Перезагружаем страницу после загрузки нового маршрута
          window.location.reload(true);
        }, 1500);
      });
    },
    btnSwitchRoot() {
      if (router.currentRoute.name === "RestaurantRoot") {
        return;
      } else {
        router.push("/RestaurantRoot");
        this.$store.commit("setRootRestaurant", {});
      }
    },
  },

  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    // let expireDate = new Date(this.user.expireDate);
    // let userRoles = this.user.roles;
    // let currentDate = new Date();
    // if (expireDate < currentDate) {
    //   this.$store.commit("setLogged", false);
    //   this.$store.dispatch("emptyUser");
    // }
  },

  components: {
    // Breadcrumbs,
  },
  computed: {
    routes() {
      return this.$router.options.routes;
    },
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>


<style scoped>
.current-rest-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rest-title {
  color: var(--04, #eaeaea);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  text-align: end;
}
.rest-adress {
  color: var(--04, #eaeaea);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  text-align: end;
}
.btn-switch {
  width: 33px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 18px;
  cursor: pointer;
}
.horizontal-line {
  background-color: #d5d5d5;
  height: 1px;
  width: 100%; /* Ширина линии равна 100% родительского контейнера */
}
.horizontal-line-not-full {
  background-color: #d5d5d5;
  height: 1px;
  width: 100%; /* Ширина линии равна 100% родительского контейнера */
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.nav-text {
  color: var(--01, #333);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 33px;
  padding-left: 16px;
  margin-bottom: 14px;
}
/* ::v-deep .v-list-item__action {
  align-self: center;
  margin: 12px 21px;
} */
.logo-icon {
  /* margin-left: 32px; */
  margin-right: 37px;
}
.admin-panel {
  color: var(--03, #bcb648);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}
::v-deep .theme--light.v-app-bar.v-toolbar.v-sheet {
  background: var(--01, #603725);
}
.logout-btn {
  display: inline-flex;
  padding: 14px 34px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid var(--white, #fff);
  background: var(--01, #333);
  margin-top: 14px;
  margin-left: 37.5px;
  cursor: pointer;
}

.centered-container {
  display: flex;
  padding: 14px 14px;
  justify-content: center;
  align-items: center;
  /* gap: 12px; */
  border-radius: 50px;
  border: 1px solid #fff;
  border: 1px solid var(--white, #fff);
  background: #333;
  background: var(--01, #333);
  /* margin: auto; */
  width: fit-content;
  /* margin: 14px auto; */
  margin-top: 14px;
  margin-left: auto;
  margin-bottom: 0px;
  margin-right: auto;
  cursor: pointer;
}
.logout-container {
  position: absolute; /* или "fixed" в зависимости от ваших потребностей */
  bottom: 21px; /* Расстояние от нижней границы родительского контейнера */
  /* Другие стили для вашего контейнера */
  width: 100%;
}

.logout-text {
  margin-bottom: 0px;
  color: var(--white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-right: 37px;
}
.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%; /* Расширяем на всю ширину родительского контейнера */
}
.profile-menu {
  position: absolute;
  bottom: -110px;
  left: -180px;
  width: 213px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.profile-menu-item-1:hover,
.profile-menu-item-2:hover {
  background-color: rgba(
    242,
    242,
    246,
    0.5
  ); /* изменяем цвет текста при наведении мыши */
}

.profile-menu-item-1 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-top-left-radius: 10px; /* Закругляем нижний левый угол */
  border-top-right-radius: 10px; /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}
.profile-menu-item-2 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-bottom-left-radius: 10px; /* Закругляем нижний левый угол */
  border-bottom-right-radius: 10px; /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}

.btn-nav-profile {
  height: 30px !important;
  width: 30px !important;
  padding: 0px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 32px;
  /* background: var(
    --gray-lighter,
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0.75) 100%
    ),
    #c2c9d1
  ); */
}

.nav-phone {
  margin-bottom: 0px !important;
  color: var(--03, #bcb648);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 27px;
}
.right-panel-nav {
  display: flex;
  align-items: center;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  align-items: stretch;
  display: flex;
  position: relative;
  z-index: 0;
  flex-direction: row;
  justify-content: space-between;
  /* height: 78px !important; */
}

.theme--light.v-divider {
  border-color: #585858;
}

::v-deep .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  border-bottom: 3px solid transparent;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
}

::v-deep .v-toolbar__title {
  color: var(--background, #f2f2f6);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ::v-deep .mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
} */
::v-deep .theme--light.v-navigation-drawer {
  background: #f4f5f6;
  box-shadow: 0px 4px 13px 0px rgba(144, 167, 188, 0.25);
}
.divider-margin {
  margin-left: 20px; /* Добавьте необходимый вам отступ */
}
::v-deep .v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px;
}
::v-deep .v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0px;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
}
::v-deep .v-list-item__action,
.v-list-item__avatar,
.v-list-item__icon {
  display: inline-flex;
  min-width: 19px;
}
.icon-image-navside {
  width: 19px; /* Ширина изображения */
  height: 19px; /* Высота изображения */
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: var(--01, #333);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-list .v-list-item--active {
  color: var(--01, #333);
  background: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-left: 5px solid #bcb648; /* Толщина, стиль и цвет левой границы */
}
::v-deep .v-list-item--link:before {
  background: var(--06, #fff) !important;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

::v-deep .v-list {
  display: block;
  padding: 0px;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.3s;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
</style>

